/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import { Styled, Heading, Flex, Text } from 'theme-ui';

import { ReflexLink } from '../components/header/navbar/reflex-link';
import SEO from '../components/seo';
import { CardContainer } from '../components/Card';

const IndexPage = ({ data }) => {
  const secondHero = {
    fluid: data.sanityMain.secondHeroImage.asset.fluid,
    to: data.sanityMain?.secondHeroLink?.internalPageRoute?.slug?.current,
    link: data.sanityMain?.secondHeroLink?.link,
  };

  return (
    <>
      <SEO title="Home" />
      <Flex
        sx={{
          width: '100%',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <BackgroundImage
          Tag="section"
          fluid={data.sanityMain.backgroundImage.asset.fluid}
          sx={{
            width: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            bg: 'dark',
          }}
        >
          <Flex
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100vw',
              height: ['100vh', null, '70vh'], // ['50vh', null, '60vh'],
              color: 'background',
            }}
          >
            <Heading
              sx={{
                p: 2,
                mt: [5, 2, 5],
                fontSize: 5,
                fontStyle: 'italic',
              }}
            >
              {data.sanityMain.heroBlurb}
            </Heading>
            <Text sx={{ px: [2, 3, 5], pt: [2, 3, 4] }}>
              {data.sanityMain.heroSubBlurb}
            </Text>
          </Flex>
        </BackgroundImage>
      </Flex>
      <CardContainer />
      {secondHero.to || secondHero.link ? (
        <ReflexLink to={secondHero.to} link={secondHero.link}>
          <Img fluid={secondHero.fluid} />
        </ReflexLink>
      ) : (
        <Img fluid={secondHero.fluid} />
      )}
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query HeroImage {
    sanityMain {
      backgroundImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      heroBlurb
      heroSubBlurb
      secondHeroImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      secondHeroLink {
        link
        internalPageRoute {
          ... on SanityPage {
            slug {
              current
            }
          }
        }
      }
    }
  }
`;
