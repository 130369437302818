/** @jsx jsx */
import { Flex, jsx, Text, Image, Button, Box } from 'theme-ui';
import Img from 'gatsby-image';
import { ArrowRight } from 'react-feather';
import { graphql, useStaticQuery } from 'gatsby';
import { ReflexLink } from './header/navbar/reflex-link';

export const CardContainer = ({ ...props }) => {
  const { cards } = useStaticQuery(graphql`
    query {
      cards: allSanityPageCard {
        nodes {
          title
          id
          link
          internalPageRoute {
            ... on SanityPage {
              slug {
                current
              }
            }
          }
          cardImage {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);
  const cardNodes = cards.nodes;
  return (
    <Flex
      sx={{
        py: [2, 3],
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        m: '0 auto',
        maxWidth: '1024px',
      }}
      {...props}
    >
      {cardNodes.map(card => (
        <Card
          image={card?.cardImage?.asset?.fluid}
          title={card.title}
          link={card.link}
          to={card.internalPageRoute.slug.current}
          key={card.id}
        />
      ))}
    </Flex>
  );
};

export const Card = ({ image, title, link, to, ...props }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      boxShadow: '2xl',
      borderRadius: 'xl',
      overflow: 'hidden',
      maxWidth: 7,
      p: 2,
      flex: '1 1 auto',
    }}
    {...props}
  >
    {image && <Img fluid={image} />}
    <Flex
      sx={{
        flexDirection: 'column',
        flex: '1',
        textAlign: 'center',
        p: [1, 1],
      }}
    >
      <Text
        sx={{
          fontSize: 2,
          fontWeight: '600',
          m: '0',
          flex: '1',
          lineHeight: '1.25',
        }}
      >
        {title}
      </Text>
      <Button as={ReflexLink} href={link} to={to} sx={{ mt: 2 }}>
        Learn more <ArrowRight sx={{ ml: 1, mb: '-6px' }} />
      </Button>
    </Flex>
  </Flex>
);
